import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ColorItem from './ColorItem';
import { Colors } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "colors"
    }}>{`Colors`}</h1>
    <p>{`Help the UI design with defined colors. You can copy the Hex code by clicking on the palette.`}</p>
    <h2 {...{
      "id": "basic"
    }}>{`Basic`}</h2>
    <ColorItem colorName="black" backgroundColor={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="white" backgroundColor={Colors.white} color={Colors.black} mdxType="ColorItem" />
    <h2 {...{
      "id": "gray-scale"
    }}>{`Gray scale`}</h2>
    <ColorItem colorName="gray900" backgroundColor={Colors.gray900} mdxType="ColorItem" />
    <ColorItem colorName="gray800" backgroundColor={Colors.gray800} mdxType="ColorItem" />
    <ColorItem colorName="gray700" backgroundColor={Colors.gray700} mdxType="ColorItem" />
    <ColorItem colorName="gray600" backgroundColor={Colors.gray600} mdxType="ColorItem" />
    <ColorItem colorName="gray500" backgroundColor={Colors.gray500} mdxType="ColorItem" />
    <ColorItem colorName="gray400" backgroundColor={Colors.gray400} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="gray300" backgroundColor={Colors.gray300} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="gray200" backgroundColor={Colors.gray200} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="gray100" backgroundColor={Colors.gray100} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="gray000" backgroundColor={Colors.gray000} color={Colors.black} mdxType="ColorItem" />
    <h2 {...{
      "id": "orange-scale"
    }}>{`Orange scale`}</h2>
    <ColorItem colorName="orange900" backgroundColor={Colors.orange900} mdxType="ColorItem" />
    <ColorItem colorName="orange800" backgroundColor={Colors.orange800} mdxType="ColorItem" />
    <ColorItem colorName="orange700" backgroundColor={Colors.orange700} mdxType="ColorItem" />
    <ColorItem colorName="orange600" backgroundColor={Colors.orange600} mdxType="ColorItem" />
    <ColorItem colorName="orange500" backgroundColor={Colors.orange500} mdxType="ColorItem" />
    <ColorItem colorName="orange400" backgroundColor={Colors.orange400} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="orange300" backgroundColor={Colors.orange300} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="orange200" backgroundColor={Colors.orange200} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="orange100" backgroundColor={Colors.orange100} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="orange000" backgroundColor={Colors.orange000} color={Colors.black} mdxType="ColorItem" />
    <h2 {...{
      "id": "red-scale"
    }}>{`Red scale`}</h2>
    <ColorItem colorName="red900" backgroundColor={Colors.red900} mdxType="ColorItem" />
    <ColorItem colorName="red800" backgroundColor={Colors.red800} mdxType="ColorItem" />
    <ColorItem colorName="red700" backgroundColor={Colors.red700} mdxType="ColorItem" />
    <ColorItem colorName="red600" backgroundColor={Colors.red600} mdxType="ColorItem" />
    <ColorItem colorName="red500" backgroundColor={Colors.red500} mdxType="ColorItem" />
    <ColorItem colorName="red400" backgroundColor={Colors.red400} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="red300" backgroundColor={Colors.red300} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="red200" backgroundColor={Colors.red200} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="red100" backgroundColor={Colors.red100} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="red000" backgroundColor={Colors.red000} color={Colors.black} mdxType="ColorItem" />
    <h2 {...{
      "id": "blue-scale"
    }}>{`Blue scale`}</h2>
    <ColorItem colorName="blue900" backgroundColor={Colors.blue900} mdxType="ColorItem" />
    <ColorItem colorName="blue800" backgroundColor={Colors.blue800} mdxType="ColorItem" />
    <ColorItem colorName="blue700" backgroundColor={Colors.blue700} mdxType="ColorItem" />
    <ColorItem colorName="blue600" backgroundColor={Colors.blue600} mdxType="ColorItem" />
    <ColorItem colorName="blue500" backgroundColor={Colors.blue500} mdxType="ColorItem" />
    <ColorItem colorName="blue400" backgroundColor={Colors.blue400} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="blue300" backgroundColor={Colors.blue300} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="blue200" backgroundColor={Colors.blue200} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="blue100" backgroundColor={Colors.blue100} color={Colors.black} mdxType="ColorItem" />
    <ColorItem colorName="blue000" backgroundColor={Colors.blue000} color={Colors.black} mdxType="ColorItem" />
    <h2 {...{
      "id": "green-scale"
    }}>{`Green scale`}</h2>
    <ColorItem colorName="green500" backgroundColor={Colors.green500} mdxType="ColorItem" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      